






























































































































import { Component, Vue } from 'vue-property-decorator';
// eslint-disable-next-line import/no-cycle
import UserService from '@/services/UserService';

@Component
export default class Login extends Vue {
  private userService = new UserService();

  public username: string = '';

  public password: string = '';

  public bank: string = '';

  public accountNumber: string = '';

  public knownUsFrom: string = '';

  public accountName: string = '';

  public loading: boolean = false;

  public async registerUser() {
    const refCode = await localStorage.getItem('demo-affiliate-program-reference');
    try {
      this.loading = true;
      // eslint-disable-next-line max-len
      await this.userService.registerUser({
        username: this.username.trim(),
        password: this.password,
        accountNumber: this.accountNumber.trim(),
        bank: this.bank,
        accountName: this.accountName,
        knownUsFrom: this.knownUsFrom,
        ref_code: refCode,
      });
      this.$store.commit('Snackbar/showNotification', { message: 'สมัครสามาชิกสำเร็จ', error: false });
      setTimeout(() => {
        this.loading = false;
        this.$router.push({ name: 'Login' });
      }, 1000);
    } catch (e) {
      this.$store.commit('Snackbar/showNotification', { message: e.message, error: true });
      this.loading = false;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  public allowOnlyNumber(event: KeyboardEvent) {
    const char = String.fromCharCode(event.keyCode); // Get the character
    if (/^[0-9]+$/.test(char)) return true;
    event.preventDefault();
    return false;
  }
}
